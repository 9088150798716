import React from "react";
import { ReactComponent as UnityIcon } from "./images/icons/Unity_icon.svg";
import { ReactComponent as SteamIcon } from "./images/icons/SteamBlack_icon.svg";
import { ReactComponent as SteamColorIcon } from "./images/icons/SteamBlack_icon.svg";
import { ReactComponent as EpicGamesIcon } from "./images/icons/EpicGame_icon.svg";
import { ReactComponent as WebIcon } from "./images/icons/Web_icon.svg";
import { ReactComponent as DiscordIcon } from "./images/icons/Discord_icon.svg";
import { ReactComponent as XIcon } from "./images/icons/X_icon.svg";
import { ReactComponent as SiteIcon } from "./images/icons/Web_icon.svg";
import { ReactComponent as AndroidIcon } from "./images/icons/Android_icon.svg";
import { ReactComponent as AndroidColorIcon } from "./images/icons/GooglePlay_icon.svg";
import { ReactComponent as AppleIcon } from "./images/icons/Apple_icon.svg";
import { ReactComponent as AppleColorIcon } from "./images/icons/AppStore_icon.svg";
import { ReactComponent as TilTokIcon } from "./images/icons/Tiktok_icon.svg";
import { ReactComponent as YoutubeIcon } from "./images/youtube-icon.svg";
import { ReactComponent as LinkedinIcon } from "./images/linkedin-icon.svg";

export const getPlatformIcon = ( platform ) => {
    switch ( platform ) {
        case 'steam':
            return <SteamIcon/>;
        case 'epic games':
            return <EpicGamesIcon/>;
        case 'discord':
            return <DiscordIcon/>
        case 'x':
            return <XIcon/>
        case 'site':
            return <SiteIcon/>
        case 'android':
            return <AndroidIcon/>;
        case 'apple':
            return <AppleIcon/>;
        case 'tiktok':
            return <TilTokIcon/>;
        case 'youtube':
            return <YoutubeIcon/>;
        case 'linkedin':
            return <LinkedinIcon/>;
        default:
            return null;
    }
};

export const getColorPlatformIcon = ( platform ) => {
    switch ( platform ) {
        case 'steam':
            return <SteamColorIcon/>;
        case 'epic games':
            return <EpicGamesIcon/>;
        case 'android':
            return <AndroidColorIcon/>;
        case 'apple':
            return <AppleColorIcon/>;
        default:
            return null;
    }
}

export const getToolIcon = ( icon ) => {
    switch ( icon ) {
        case 'unity':
            return <UnityIcon/>;
        case 'web':
            return <WebIcon/>;
        default:
            return null;
    }
};
