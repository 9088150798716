import React, { useState, useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/custom-header.js";
import Hero from "components/hero/custom-hero.js";
import Features from 'components/features/custom-features.js';
import Projects from 'components/cards/custom-cards.js';
import FAQs from 'components/faqs/custom-faqs.js';
import Footer from 'components/footers/custom-footer.js';
import ContactUs from "components/forms/custom-contact-us.js";

export default ({}) => {
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);


    useEffect(() => {
        window.gtag("js", new Date());
        window.gtag("config", "UA-45799926-9");
    }, []);

    const openContactModal = () => {
        setIsContactModalOpen(true);
    };

    const closeContactModal = () => {
        setIsContactModalOpen(false);
    };

    return (
        <AnimationRevealPage disabled>
            <Header onContactButtonClick={openContactModal}/>
            <Hero onContactButtonClick={openContactModal}/>
            <Features/>
            <Projects/>
            <FAQs/>
            <Footer onContactButtonClick={openContactModal}/>
            <ContactUs isOpen={isContactModalOpen} onClose={closeContactModal}/>
        </AnimationRevealPage>
    );
};
