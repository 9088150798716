import React, { useState, useEffect, useContext } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/custom-header.js";
import Projects from 'components/cards/custom-cards.js';
import { getProjects, translations } from '../Projects';
import Footer from 'components/footers/custom-footer.js';
import ContactUs from "components/forms/custom-contact-us.js";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getPlatformIcon, getColorPlatformIcon, getToolIcon } from "../icons";
import tw from "twin.macro";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { LanguageContext } from '../LanguageContext';

const ProjectData = await getProjects();

const Container = styled.div`
    padding: 3rem 2rem;
    max-width: 1200px;
    margin: 5% auto;
    display: flex;
    gap: 2rem;

    @media (max-width: 1024px) {
        flex-direction: column;
        padding: 0 0 3rem 0;
        gap: 0;
    }
`;

const MainColumn = styled.div`
    flex: 2;
    width: 66%;
    order: 0;

    @media (max-width: 1024px) {
        width: 100%;
        order: 1;
    }
`;

const Sidebar = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 5%;
    height: 5%;
    order: 0;
    padding: 2%;
    outline: transparent solid 1px;
    border-radius: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .IconContainer {
        margin-right: 2%;
    }

    @media (max-width: 1024px) {
        display: none;
    }
`;


const SidebarImage = styled.div`
    img {
        width: 100%;
        height: auto;
        border-radius: 4px;
    }

    @media (max-width: 1024px) {

        img {
            height: 280px;
            border-radius: 10px;
            margin: 1.5% 0;
        }

    }
`;

const Divider = styled.div`
    outline: 1px solid black;
    height: 75%;
    margin: 0 3% 0 0;

    @media (max-width: 768px) {
        display: none;
    }
`;

const GameInfo = styled.div`
    display: flex;
    flex-direction: column;

    p {
        font-size: 1rem;
    }
`

const ProjectInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 6.5rem;
    margin: 4% 0;


    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        margin: 10% 0;
    }
`

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1% 2% 2% 0;
    justify-content: space-between;

    h1 {
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 10px;
    }

    &.technology {
        flex-basis: 25%;
    }

    &.social {
        flex-basis: 25%;
    }

    &.tags {
        flex-basis: 50%;
    }

    @media (max-width: 768px) {
        &.technology,
        &.social,
        &.tags {
            flex-basis: 100%;
        }
    }
`;

const GameDetails = styled.div`
`;


export const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex-direction: column;

    @media (max-width: 1024px) {
        justify-content: center;
        width: 25%;
    }

    a {
        padding: 0.5rem;
        font-size: 1rem;
        background: linear-gradient(90deg, rgba(0, 94, 255, 1) 0%, rgb(0, 170, 213) 100%);
        color: white;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease, background-color 0.3s ease;
        font-weight: bold;

        &:hover {
            transform: scale(1.1);
            background: linear-gradient(90deg, rgba(0, 115, 255, 1) 0%, rgb(0, 184, 230) 100%);
        }

        &:active {
            transform: scale(1.05);
        }

        @media (max-width: 1024px) {

            svg {
                margin: 0;
            }

            &:hover {
                transform: scale(1.05);
            }
        }
    }
`;

const Tags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    span {
        padding: 0.3rem 0.45rem;
        font-size: 0.9rem;
        background-color: transparent;
        color: black;
        border-radius: 3px;
        font-weight: 600;
        text-transform: uppercase;
        outline: solid 1px black;
        transition: transform 0.2s ease;
        user-select: none;
        cursor: default;

        &:hover {
            transform: scale(1.1);
        }
    }

    @media (max-width: 768px) {

        span {
            font-size: 0.8rem;
        }
    }
`;


const IconContainer = styled.div`
    ${ tw`inline-block rounded-full p-0 text-black` }
    svg {
        ${ tw`w-8 h-8` }
    }
`;


const ButtonGroupDescription = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    flex-direction: row;
    justify-content: center;

    a {
        padding: 0.5rem;
        font-size: 0;
        background: linear-gradient(90deg, rgba(0, 94, 255, 1) 0%, rgba(0, 201, 255, 1) 100%);
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease, background-color 0.3s ease;
        margin-top: 3%;

        &:hover {
            transform: scale(1.05);
            background: linear-gradient(90deg, rgba(0, 115, 255, 1) 0%, rgba(0, 225, 255, 1) 100%);
        }

        &:active {
            transform: scale(1.05);
        }

        ${ IconContainer } {
            svg {
                ${ tw`w-10 h-10` }
            }
        }
    }

    @media (min-width: 1024px) {
        display: none;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 5%;
        gap: 0.5rem;

        a {
            font-size: 1rem;
            font-weight: bold;
        }

        ${ IconContainer } {
            svg {
                ${ tw`w-8 h-8 mr-1` }
            }
        }
    }
`;


const Technology = styled.div`
    display: flex;
`

const SocialLink = styled.a`
    ${ tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-[0.3rem] mb-[0]
    ` }
    svg {
        ${ tw`w-[1.3rem;] h-[1.3rem;]` }
    }

    @media (max-width: 768px) {
        ${ tw`mr-[1rem] mb-[1rem]` }
        svg {
            ${ tw`w-6 h-6` }
        }
    }
`;


const SocialMedia = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

`
const IconWithText = styled.div`
    ${ tw`flex items-center` }
    transition: transform 0.3s ease, color 0.3s ease;

    a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: black;
        transition: transform 0.3s ease, color 0.3s ease;

        &:hover {
            color: #6C63FF;
            transform: scale(1.05);
        }

        &:active {
            transform: scale(1.05);
        }
    }
}
`;

const TitleContainer = styled.div`
    order: 1;

    @media (max-width: 1024px) {
        display: none;
    }
`;

const TitleContainerTablet = styled.div`
    order: -2;
    display: flex;
    text-align: center;

    @media (min-width: 1024px) {
        display: none;
    }
`;

const StyledDescription = styled.div`
    ${tw`mt-6`}

    h2 {
        ${tw`text-2xl font-bold mt-6 mb-2`}
    }

    p {
        ${tw`mb-0 text-base leading-relaxed`}
    }

    ul {
        ${tw`list-disc list-inside mb-0`}
    }

    li {
        ${tw`mb-0`}
    }

    ul + p {
        ${tw`mt-2`}
    }

    img {
        ${tw`my-4 w-full h-auto mb-6`}
    }

    b {
        ${tw`font-bold`}
    }

    i {
        ${tw`italic`}
    }

    u {
        ${tw`underline`}
    }

    br {
        ${tw`block`}
    }
`;

function parseBBCode(description, mediaDescription) {
    let html = description;

    // Создаём карту (объект) для быстрого поиска URL по имени
    const mediaMap = {};
    mediaDescription.forEach(media => {
        mediaMap[media.name.trim().toLowerCase()] = media.url;
    });

    console.log("Media Map:", mediaMap); // Для отладки

    // Заменяем [img]{name}[/img] на соответствующий <img> тег
    html = html.replace(/\[img\]{([^}]+)}\[\/img\]/g, function(match, p1) {
        const nameKey = p1.trim().toLowerCase(); // Удаляем пробелы и приводим к нижнему регистру
        const url = mediaMap[nameKey];
        if (url) {
            return `<img src="${url}" alt="${p1}"/>`;
        } else {
            console.warn(`Image isn't found for name: ${p1}`);
            return `<span style="color: red;">Image isn't found: ${p1}</span>`;
        }
    });

    // Заменяем другие BBCode теги на соответствующие HTML теги
    html = html.replace(/\[h2\](.*?)\[\/h2\]/gis, '<h2>$1</h2>');
    html = html.replace(/\[ul\](.*?)\[\/ul\]/gis, '<ul>$1</ul>');
    html = html.replace(/\[li\](.*?)\[\/li\]/gis, '<li>$1</li>');
    html = html.replace(/\[b\](.*?)\[\/b\]/gis, '<b>$1</b>');
    html = html.replace(/\[i\](.*?)\[\/i\]/gis, '<i>$1</i>');
    html = html.replace(/\[u\](.*?)\[\/u\]/gis, '<u>$1</u>');

    // Обрабатываем переносы строк
    html = html.replace(/\r\n/g, '\n');
    html = html.replace(/\n{2,}/g, '</p><p>'); // Двойные и более переносы = новый параграф
    html = html.replace(/\n/g, '<br/>'); // Одиночные переносы = перенос строки
    html = '<p>' + html + '</p>'; // Оборачиваем весь текст в параграфы

    return html;
}

export default () => {
    const { id } = useParams();
    const project = ProjectData.find( p => p.slug === id );
    const [ isContactModalOpen, setIsContactModalOpen ] = useState( false );
    const { selectedLanguage } = useContext( LanguageContext );

    useEffect( () => {
        window.gtag( "js", new Date() );
        window.gtag( "config", "UA-45799926-9" );
    }, [] );

    const openContactModal = () => {
        setIsContactModalOpen( true );
    };

    const closeContactModal = () => {
        setIsContactModalOpen( false );
    };

    const isValidValue = ( value ) => {
        if ( Array.isArray( value ) ) {
            return value.length > 0;
        }
        return value !== undefined && value !== null && value !== "";
    };

    return (
        <AnimationRevealPage disabled>
            <Header onContactButtonClick={ openContactModal }/>
            <Container>
                <ContactUs isOpen={ isContactModalOpen } onClose={ closeContactModal }/>
                { isValidValue( project.title ) && (
                    <TitleContainerTablet>
                        <h1
                            style={ {
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                            } }
                        >
                            { project.title }
                        </h1>
                    </TitleContainerTablet>
                ) }
                <MainColumn>
                    { isValidValue( project.title ) && (
                        <TitleContainer>
                            <h1
                                style={ {
                                    fontSize: "2.5rem",
                                    fontWeight: "bold",
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                } }
                            >
                                { project.title }
                            </h1>
                        </TitleContainer>
                    ) }
                    { isValidValue( project.trailer ) ? (
                        // Карусель с трейлером и скриншотами
                        <Carousel
                            renderIndicator={ false }
                            infiniteLoop={ true }
                            emulateTouch={ true }
                            autoPlay={ true }
                            interval={ 6000 }
                            showIndicators={ false }
                            showStatus={ false }
                            thumbWidth={ 120 }
                            renderItem={ ( item, props ) => <item.type { ...item.props } { ...props } /> }
                        >
                            {/* Отображение трейлера */ }
                            <div
                                key="video-0"
                                className="video-slide"
                                style={ { width: "100%", height: "100%" } }
                            >
                                <iframe
                                    src={ `${ project.trailer }?autoplay=1&rel=0` }
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin"
                                    allowFullScreen
                                    style={ { width: "100%", height: "100%", border: "none", margin: "0" } }
                                ></iframe>
                            </div>

                            {/* Отображение скриншотов */ }
                            { isValidValue( project.screenshots ) &&
                                project.screenshots.map( ( image, index ) => (
                                    <div key={ `image-${ index + 1 }` } className="border-8">
                                        <img src={ image } alt={ `Screenshot ${ index + 1 }` }/>
                                    </div>
                                ) ) }
                        </Carousel>
                    ) : isValidValue( project.screenshots ) ? (
                        // Карусель только со скриншотами
                        <Carousel
                            renderIndicator={ false }
                            infiniteLoop={ true }
                            emulateTouch={ true }
                            autoPlay={ true }
                            interval={ 6000 }
                            showIndicators={ false }
                            showStatus={ false }
                            thumbWidth={ 120 }
                            renderItem={ ( item, props ) => <item.type { ...item.props } { ...props } /> }
                        >
                            { project.screenshots.map( ( image, index ) => (
                                <div key={ `image-${ index + 1 }` } className="border-8">
                                    <img src={ image } alt={ `Screenshot ${ index + 1 }` }/>
                                </div>
                            ) ) }
                        </Carousel>
                    ) : null }

                    <GameInfo>
                        { isValidValue( project.shortDescription[selectedLanguage]) && (
                            <p>{project.shortDescription[selectedLanguage]}</p>
                        )}

                        <ButtonGroupDescription>
                            {isValidValue(project.platforms) &&
                                Object.entries(project.platforms).map(([platform, link], index) => (
                                    <a
                                        key={platform}
                                        href={link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{
                                            textTransform: "capitalize",
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '0.5rem',
                                            textDecoration: 'none',
                                            color: 'inherit'
                                        }}
                                    >
                                        <IconContainer>
                                            {getColorPlatformIcon(platform)}
                                        </IconContainer>
                                        {platform}
                                    </a>
                                ))
                            }
                        </ButtonGroupDescription>

                        <ProjectInfo>
                            {(() => {
                                const sections = [];

                                // Technology Section
                                if (isValidValue(project.technology)) {
                                    sections.push(
                                        <DetailsContainer className="technology" key="technology">
                                            <h1>{translations.projectDetails[selectedLanguage]?.technology || "Technology:"}</h1>
                                            <Technology>
                                                {project.technology.map((technology, idx) => (
                                                    <IconContainer key={idx} style={{ marginRight: '0.5rem' }}>
                                                        {getToolIcon(technology.toLowerCase())}
                                                    </IconContainer>
                                                ))}
                                            </Technology>
                                        </DetailsContainer>
                                    );
                                }

                                // Social Media Section
                                if (isValidValue(project.socialMedia)) {
                                    sections.push(
                                        <DetailsContainer className="social" key="social">
                                            <h1>{translations.projectDetails[selectedLanguage]?.socialMedia || "Social Media:"}</h1>
                                            <SocialMedia>
                                                {Object.entries(project.socialMedia).map(([social, link], index) => (
                                                    isValidValue(link) && (
                                                        <IconWithText key={`${social}-${index}`}>
                                                            <a
                                                                href={link}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: '0.5rem',
                                                                    textDecoration: 'none',
                                                                    color: 'inherit'
                                                                }}
                                                            >
                                                                <SocialLink>
                                                                    <IconContainer style={{ filter: 'invert(1)' }}>
                                                                        {getPlatformIcon(social.toLowerCase())}
                                                                    </IconContainer>
                                                                </SocialLink>
                                                            </a>
                                                        </IconWithText>
                                                    )
                                                ))}
                                            </SocialMedia>
                                        </DetailsContainer>
                                    );
                                }

                                // Tags Section
                                if (isValidValue(project.tags)) {
                                    sections.push(
                                        <DetailsContainer className="tags" key="tags">
                                            <h1>{translations.projectDetails[selectedLanguage]?.tags || 'Tags:'}</h1>
                                            <Tags>
                                                {project.tags.map((tag, index) => (
                                                    <span key={index}>{tag}</span>
                                                ))}
                                            </Tags>
                                        </DetailsContainer>
                                    );
                                }

                                // Возвращаем секции с Divider между ними, если их больше одной
                                return sections.length > 0 ? (
                                    sections.reduce((acc, section, idx) => {
                                        acc.push(section);
                                        if (idx < sections.length - 1) {
                                            acc.push(<Divider key={`divider-${idx}`} />);
                                        }
                                        return acc;
                                    }, [])
                                ) : null;
                            })()}
                        </ProjectInfo>
                    </GameInfo>

                    <GameDetails>
                        <h1 style={ { fontSize: "2rem", fontWeight: "bold", marginBottom: "2%" } }>
                            { translations.projectDetails[ selectedLanguage ].about }
                        </h1>

                        {isValidValue(project.description[selectedLanguage]) && (
                            <StyledDescription
                                dangerouslySetInnerHTML={{
                                    __html: parseBBCode(
                                        project.description[selectedLanguage],
                                        project?.mediaDescription || []
                                    ),
                                }}
                            />
                        )}
                    </GameDetails>

                    { isValidValue( project.idSteam ) && (
                        <iframe
                            src={ `https://store.steampowered.com/widget/${ project.idSteam }/?utm_source=homepage&utm_campaign=mycampaign` }
                            width="100%"
                            height="200"
                            allowFullScreen
                            style={ { marginTop: "10%" } }
                        ></iframe>
                    ) }
                </MainColumn>

                { isValidValue( project.icon ) && (
                    <Sidebar>
                        <SidebarImage>
                            { isValidValue( project.icon ) && (
                                <img
                                    src={ project.icon }
                                    alt={ `${ project.title } Sidebar Image` }
                                />
                            ) }
                        </SidebarImage>

                        <ButtonGroup>
                            {isValidValue(project.platforms) && Object.keys(project.platforms).length > 0 ? (
                                Object.entries(project.platforms).map(([platform, link], idx) => (
                                    <a
                                        key={idx}
                                        href={link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        <IconContainer style={{marginRight: '2%' }}>
                                            {getColorPlatformIcon(platform)}
                                        </IconContainer>
                                        {platform}
                                    </a>
                                ))
                            ) : (
                                <p style={{ textTransform: "capitalize", textDecoration: "none", textAlign: 'center', fontSize: '2cqw', fontWeight: '500'}}>Coming soon in Platforms</p>
                            )}
                        </ButtonGroup>
                    </Sidebar>
                ) }
            </Container>

            <Projects ignoreProjectId={ project.id }/>

            <p style={{paddingBottom: '8rem'}}/>

            <Footer onContactButtonClick={ openContactModal }/>
        </AnimationRevealPage>
    );

};
