import React, { useContext, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { getProjects, translations } from '../../Projects';
import { LanguageContext } from '../../LanguageContext';

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import logo from "../../images/logo.svg";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const ProjectData = await getProjects();


const Header = tw.header`
  w-full 
  flex justify-center items-center
  mx-auto fixed top-0 right-0 z-50 
  bg-white
`;

const HeaderContainer = styled.div`
    max-width: 1280px;
    width: 100%;
`;

const Container = tw.div`w-full flex justify-center`;


export const NavLinks = tw.div`inline-block`;
export const NavLink = styled.a`
    ${tw`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500 relative inline-block`}

    &:hover .dropdown-menu {
        display: block;
    }
`;



export const PrimaryLink = styled(NavLink)`
    ${tw`
    lg:mx-0
    rounded-lg
    px-8 py-3 rounded
    text-white
    hocus:text-white focus:shadow-outline
    border-b-0
  `}
    background: linear-gradient(90deg, rgba(0, 94, 255, 1) 0%, rgb(0, 170, 213) 100%);
    cursor: pointer;
    user-select: none;
    transition: transform 0.3s ease, background 0.3s ease; /* Плавный переход для изменений */

    &:hover {
        background: linear-gradient(90deg, rgba(0, 115, 255, 1) 0%, rgb(0, 170, 230) 100%);
        transform: scale(1.02); /* Увеличение размера на 2% */
        color: white;
    }
`;


export const LogoLink = styled.a`
    ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

    img {
        width: 12rem;
        height: 6rem;
    }
`;


export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between `;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
    ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-16 my-6 p-8 border text-center justify-center rounded-lg text-gray-900 bg-white`}
    ${NavLinks} {
        ${tw`flex flex-col items-center`}
    }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

const DropdownMenu = styled.div`
    ${tw`absolute mt-[-45%] mr-[-60%] right-0 rounded-lg shadow-lg`}
    display: none;
`;

const DropdownItem = tw.a`
  block px-4 py-2 text-sm text-gray-700 hover:bg-gray-200 bg-white 
`;

const LanguageDropdownMenu = styled.div`
    ${tw`absolute mt-[-155%] ml-[-80%] rounded-lg shadow-lg`}
    display: none;
`;

export const NavLinkWithLanguage = styled(NavLink)`
    ${tw`text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
    font-semibold tracking-wide transition duration-300
    border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500 relative inline-block`}
    display: inline-block;
    min-width: 20px;
    width: 20px;
    text-align: center;
`;


const HeaderComponent = ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg", onContactButtonClick }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);

    const languages = ['ua', 'ru', 'en', 'ca'];
    const dropdownRef = useRef(null);

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
        setDropdownOpen(false);
    };

    const headerTexts = translations.header[selectedLanguage] || {};

    const sortedLanguages = languages
    .filter(lang => lang !== selectedLanguage)
    .sort((a, b) => a.localeCompare(b));

    const languageOptions = [selectedLanguage, ...sortedLanguages];

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const defaultLinks = [
        <NavLinks key={1}>
            <NavLink href="/#">{headerTexts.home}</NavLink>
            <NavLink href="/#expertise">{headerTexts.expertise}</NavLink>
            <NavLink href="/#projects">
                {headerTexts.projects}
                <DropdownMenu isOpen={isDropdownOpen} className="dropdown-menu">
                    <DropdownItem style={{ background: 'transparent' }}>&nbsp;</DropdownItem>
                    {ProjectData.map(project => (
                        <DropdownItem key={project.slug} href={`/Project/${project.slug}`}>{project.title}</DropdownItem>
                    ))}
                </DropdownMenu>
            </NavLink>
            <NavLink href="/#faq">{headerTexts.faq}</NavLink>
            <PrimaryLink css={roundedHeaderButton && tw`rounded-full`} onClick={onContactButtonClick}>{headerTexts.contact}</PrimaryLink>
            <NavLinkWithLanguage onClick={handleDropdownToggle}>
                {selectedLanguage.toUpperCase()}
                <LanguageDropdownMenu ref={dropdownRef} className="dropdown-menu">
                    <DropdownItem style={{ background: 'transparent' }}>&nbsp;</DropdownItem>
                    {languageOptions.slice(1).map((lang) => (
                        <DropdownItem key={lang} onClick={() => handleLanguageChange(lang)}>
                            {lang.toUpperCase()}
                        </DropdownItem>
                    ))}
                </LanguageDropdownMenu>
            </NavLinkWithLanguage>
        </NavLinks>
    ];

    const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
    const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass];

    logoLink = logoLink || (
        <LogoLink href="/">
            <img src={logo} alt="logo" />
        </LogoLink>
    );
    links = links || defaultLinks;

    return (
        <Header className={className || "header-light"}>
            <Container>
                <HeaderContainer>
                    <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
                        {logoLink}
                        {links}
                    </DesktopNavLinks>

                    <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
                        {logoLink}
                        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
                            {links}
                        </MobileNavLinks>
                        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
                            {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
                        </NavToggle>
                    </MobileNavLinksContainer>
                </HeaderContainer>
            </Container>
        </Header>
    );
};


export default HeaderComponent;

const collapseBreakPointCssMap = {
    sm: {
        mobileNavLinks: tw`sm:hidden`,
        desktopNavLinks: tw`sm:flex`,
        mobileNavLinksContainer: tw`sm:hidden`
    },
    md: {
        mobileNavLinks: tw`md:hidden`,
        desktopNavLinks: tw`md:flex`,
        mobileNavLinksContainer: tw`md:hidden`
    },
    lg: {
        mobileNavLinks: tw`lg:hidden`,
        desktopNavLinks: tw`lg:flex`,
        mobileNavLinksContainer: tw`lg:hidden`
    },
    xl: {
        mobileNavLinks: tw`lg:hidden`,
        desktopNavLinks: tw`lg:flex`,
        mobileNavLinksContainer: tw`lg:hidden`
    }
};
