import React, { useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/dot-pattern.svg";
import NastyCat from "components/3d/Cat/NastyCat.js";
import { translations } from "../../Projects";
import { LanguageContext } from "../../LanguageContext";


const Container = tw.div`relative pt-[5%]`;
const TwoColumn = tw.div`flex flex-col justify-between lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24`;
const LeftColumn = tw.div`relative lg:w-6/12 lg:pr-12 flex-shrink-0 text-center lg:text-left`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex flex-col justify-center h-full`;

const Heading = tw.h1`font-black text-3xl md:text-5xl leading-snug max-w-3xl`;
const Paragraph = tw.p`my-5 lg:my-8 text-sm lg:text-base font-medium text-gray-600 max-w-lg mx-auto lg:mx-0`;

const Actions = tw.div`flex flex-col items-center sm:flex-row justify-center lg:justify-start mt-8`;
const PrimaryButton = styled.button`
    ${tw`
    font-bold px-8 lg:px-10 py-3
    rounded-lg
    text-white
    hocus:text-white focus:shadow-outline focus:outline-none
    transition duration-300
  `}
    background: linear-gradient(90deg, rgba(0, 94, 255, 1) 0%, rgb(0, 170, 213) 100%);
    cursor: pointer;
    user-select: none;
    color: white;

    &:hover {
        background: linear-gradient(90deg, rgba(0, 115, 255, 1) 0%, rgb(0, 170, 230) 100%);
        transform: scale(1.05);
        transition: transform 0.4s ease, background 0.4s ease;
        color: white;
    }
`;

const IllustrationContainer = tw.div`hidden md:flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none w-[500px] h-[500px]`;

const DecoratorBlob1 = styled( SvgDecoratorBlob1 )`
    ${ tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3  -z-10` }
`;
const DecoratorBlob2 = styled( SvgDecoratorBlob2 )`
    ${ tw`pointer-events-none fill-current text-primary-500 opacity-50 absolute w-32 h-32 right-0 bottom-0 transform translate-x-10 translate-y-10 -z-10` }
`;


// Hero компонент
export default ({ onContactButtonClick }) => {
    const { selectedLanguage } = useContext(LanguageContext);

    const heroTexts = translations.hero[selectedLanguage] || {};


    return (
        <Container>
            <TwoColumn>
                <LeftColumn>
                    <Heading>{heroTexts.heading}</Heading>
                    <Paragraph>{heroTexts.description}</Paragraph>
                    <Actions>
                        <PrimaryButton as="a" href="#" onClick={onContactButtonClick}>
                            {heroTexts.primaryButtonText}
                        </PrimaryButton>
                    </Actions>
                </LeftColumn>
                <RightColumn>
                    <IllustrationContainer>
                        <DecoratorBlob2 />
                        <NastyCat />
                    </IllustrationContainer>
                </RightColumn>
            </TwoColumn>
            <DecoratorBlob1 />
        </Container>
    );
};
