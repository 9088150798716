// LanguageContext.js
import React, { createContext, useState, useEffect } from 'react';

export const LanguageContext = createContext();

const supportedLanguages = ['ua', 'ru', 'en', 'ca'];

const getDefaultLanguage = () => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
        return savedLanguage;
    }
    const userLanguage = navigator.language.split('-')[0];
    return supportedLanguages.includes(userLanguage) ? userLanguage : 'en';
};

export const LanguageProvider = ({ children }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(getDefaultLanguage);

    useEffect(() => {
        localStorage.setItem('selectedLanguage', selectedLanguage);
    }, [selectedLanguage]);

    return (
        <LanguageContext.Provider value={{ selectedLanguage, setSelectedLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};
