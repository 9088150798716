import React, { useEffect, useRef } from 'react';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';

export const CatHead = ({ trackOnWindow = false }) => {
    const canvasRef = useRef(null);
    let model;

    useEffect(() => {
        const canvas = canvasRef.current;
        const initialCanvasWidth = canvas.clientWidth;
        const engine = new BABYLON.Engine(canvas, true, { preserveDrawingBuffer: true, stencil: true });

        engine.loadingScreen = null;
        BABYLON.SceneLoader.ShowLoadingScreen = false;

        const createScene = () => {
            const scene = new BABYLON.Scene(engine);
            scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);

            const camera = new BABYLON.FreeCamera("camera", new BABYLON.Vector3(0, 0, -10), scene);
            const light = new BABYLON.DirectionalLight("DirectionalLight", new BABYLON.Vector3(0, -20, 30), scene);
            light.intensity = 10;

            const targetCube = BABYLON.Mesh.CreateBox("targetCube", 2, scene);
            targetCube.position.z = 10;
            targetCube.isVisible = false;



            BABYLON.SceneLoader.ImportMesh("", "/", "CatHead.gltf", scene, meshes => {
                model = meshes[0];
                model.scaling = new BABYLON.Vector3(20, 20, 20);

                const material = new BABYLON.StandardMaterial("transparentMaterial", scene);
                material.alpha = 0.5;
                material.backFaceCulling = false;
                material.diffuseColor = new BABYLON.Color3(1, 1, 1);
                meshes[3].material = material;
            });

            const handleMouseMove = event => {
                if (model) {
                    const boundingRect = canvas.getBoundingClientRect();
                    const pointerX = event.clientX - boundingRect.left;
                    const pointerY = event.clientY - boundingRect.top;
                    const targetPosition = new BABYLON.Vector3(pointerX, pointerY, 0.8);
                    const vector = BABYLON.Vector3.Unproject(
                        targetPosition,
                        engine.getRenderWidth(),
                        engine.getRenderHeight(),
                        BABYLON.Matrix.Identity(), scene.getViewMatrix(),
                        scene.getProjectionMatrix()
                    );
                    model.lookAt(vector);
                }
            };

            const mouseMoveTarget = trackOnWindow ? window : canvas;
            mouseMoveTarget.addEventListener("mousemove", handleMouseMove);

            return scene;
        };

        const scene = createScene();
        engine.runRenderLoop(() => {
            scene.render();
        });



        window.addEventListener("resize", () => {
            engine.resize();
            if (model) {
                const scaleFactor = Math.max(0.1, Math.min(1, canvas.clientWidth / initialCanvasWidth));
                model.scaling = new BABYLON.Vector3(20 * scaleFactor, 20 * scaleFactor, 20 * scaleFactor);
                console.log(scaleFactor)
            }
        });


        return () => {
            engine.dispose();
            window.removeEventListener("resize", engine.resize);
        };
    }, [trackOnWindow]);



    return <canvas
        ref={ canvasRef }
        id="renderCanvas"
        style={ {
            width: '100%',
            height: '100%',
            userSelect: 'none',
            outline: 'none',
            pointerEvents: 'none',
        } }
    />;
};