import tw from "twin.macro";
import styled from "styled-components";

export const PrimaryButton = styled.button`
  ${tw`px-8 py-3 font-bold rounded text-white focus:shadow-outline focus:outline-none transition duration-300`}
  background: linear-gradient(90deg, rgba(0, 94, 255, 1) 0%, rgb(0, 170, 213) 100%);
  cursor: pointer;
  user-select: none;

  &:hover {
    background: linear-gradient(90deg, rgba(0, 115, 255, 1) 0%, rgb(0, 184, 230) 100%);
    color: white;
  }

  &:focus {
    box-shadow: 0 0 0 3px rgba(176, 250, 255, 0.5);
  }
`;

