import React, { useContext } from "react";
import tw from "twin.macro";
import styled from "styled-components";

import LogoImage from "../../images/logo_horizontal.svg";
import { ReactComponent as LinkedinIcon } from "../../images/linkedin-icon.svg";
import { ReactComponent as XIcon } from "../../images/icons/X_icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as DiscordIcon } from "../../images/icons/Discord_icon.svg";
import { ReactComponent as SteamIcon } from "../../images/icons/SteamBlack_icon.svg";
import { ReactComponent as TilTokIcon } from "../../images/icons/Tiktok_icon.svg";
import { translations } from "../../Projects";
import { LanguageContext } from "../../LanguageContext";


const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw( Column )`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = styled.img`
    width: 12rem;
`;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
    ${ tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4` }
    svg {
        ${ tw`w-4 h-4` }
    }
`;

export const PrimaryButton = styled.button`
    ${tw`
    font-bold px-8 lg:px-10 py-3
    rounded-lg
    text-white
    hocus:text-white focus:shadow-outline focus:outline-none
    transition duration-300 mt-4
  `}
    background: linear-gradient(90deg, rgba(0, 94, 255, 1) 0%, rgb(0, 170, 213) 100%);
    cursor: pointer;
    user-select: none;
    transition: transform 0.3s ease, background 0.3s ease;
    color: white;

    &:hover {
        background: linear-gradient(90deg, rgba(0, 115, 255, 1) 0%, rgb(0, 170, 230) 100%);
        transform: scale(1.05);
        color: white;
    }
`;


export default ({
        onContactButtonClick,
        primaryButtonUrl = '#',
    }) => {

    const { selectedLanguage } = useContext(LanguageContext);

    const translation = translations.footer[selectedLanguage] || {};

    return (
        <Container>
            <FiveColumns>
                <WideColumn>
                    <LogoContainer>
                        <LogoImg src={ LogoImage }/>
                    </LogoContainer>
                        <PrimaryButton href={ primaryButtonUrl } onClick={onContactButtonClick}>
                            { translation.contact || 'Contact Us' }
                        </PrimaryButton>
                    <SocialLinksContainer>
                        <SocialLink href='https://store.steampowered.com/developer/nastycat'>
                            <SteamIcon style={{ filter: 'invert(1)' }}/>
                        </SocialLink>
                        <SocialLink href='https://discord.com/invite/jY5nzVY6As'>
                            <DiscordIcon style={{ filter: 'invert(1)' }}/>
                        </SocialLink>
                        <SocialLink href='https://www.tiktok.com/@sharded_world'>
                            <TilTokIcon style={{ filter: 'invert(1)' }}/>
                        </SocialLink>
                        <SocialLink href='https://www.youtube.com/@Sharded_World'>
                            <YoutubeIcon/>
                        </SocialLink>
                        <SocialLink href='https://x.com/sharded_world' >
                            <XIcon style={{ filter: 'invert(1)' }} />
                        </SocialLink>
                        <SocialLink href='https://www.linkedin.com/company/nasty-cat/'>
                            <LinkedinIcon/>
                        </SocialLink>
                    </SocialLinksContainer>
                </WideColumn>
                <Column>
                    <ColumnHeading>{ translation.links }</ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <Link href="#">{ translation.about }</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="#faq">{ translation.FAQs }</Link>
                        </LinkListItem>

                    </LinkList>
                </Column>
                <Column>
                    <ColumnHeading>{ translation.product }</ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <Link href="#projects">{ translation.projects }</Link>
                        </LinkListItem>
                    </LinkList>
                </Column>
                <Column>
                    <ColumnHeading>{ translation.legal }</ColumnHeading>
                    <LinkList>
                        <LinkListItem>
                            <Link href="/PrivacyPolicy">{ translation.privacy }</Link>
                        </LinkListItem>
                        <LinkListItem>
                            <Link href="/TermsOfService">{ translation.terms }</Link>
                        </LinkListItem>
                    </LinkList>
                </Column>
            </FiveColumns>
        </Container>
    );
};
