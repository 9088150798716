import ShieldIconImage from "images/shield-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import SupportIconImage from "images/support-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

import axios from "axios";

export async function getProjects() {
    const projectsData = await axios.get( '/api/index.php?action=allProjects&pdev=' + new URLSearchParams( window.location.search ).get( 'pdev' ) );
    return projectsData.data;
}

export const translations = {
    header: {
        en: {
            home: 'Home',
            expertise: 'Expertise',
            projects: 'Projects',
            faq: 'FAQ',
            contact: 'Contact Us'
        },
        ua: {
            home: 'Головна',
            expertise: 'Досвід',
            projects: 'Проекти',
            faq: 'Часті питання',
            contact: 'Зв\'яжіться з нами'
        },
        ru: {
            home: 'Главная',
            expertise: 'Опыт',
            projects: 'Проекты',
            faq: 'Вопросы и ответы',
            contact: 'Свяжитесь с нами'
        },
        ca: {
            home: 'Inici',
            expertise: 'Expertesa',
            projects: 'Projectes',
            faq: 'FAQ',
            contact: 'Contacte'
        }
    },
    hero: {
        en: {
            heading: "Welcome to Nasty Cat",
            description: "The proud team of indie developers from Ukraine 🇺🇦",
            primaryButtonText: "Get Started"
        },
        ua: {
            heading: "Ласкаво просимо до Nasty Cat",
            description: "Горда команда інді-розробників з України 🇺🇦",
            primaryButtonText: "Почати"
        },
        ru: {
            heading: "Добро пожаловать в Nasty Cat",
            description: "Гордая команда независимых разработчиков из Украины 🇺🇦",
            primaryButtonText: "Начать"
        },
        ca: {
            heading: "Benvingut a Nasty Cat",
            description: "L'equip orgullós de desenvolupadors independents d'Ucraïna 🇺🇦",
            primaryButtonText: "Començar"
        }
    },
    features: {
        card: {
            en: {
                heading: 'Amazing Expertise',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
            },
            ua: {
                heading: 'Дивовижний досвід',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
            },
            ru: {
                heading: 'Удивительный опыт',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
            },
            ca: {
                heading: 'Especialitat increïble',
                description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
            },
        },
        expertise: {
            en: [
                {
                    imageSrc: ShieldIconImage,
                    title: "Unity",
                    description: "High-performance game and app development to maximize results with Unity's advanced tools"
                },
                {
                    imageSrc: FastIconImage,
                    title: "Fast",
                    description: "We optimize every stage of development to create high-quality products in the shortest possible time"
                },
                {
                    imageSrc: CustomizeIconImage,
                    title: "Customizable",
                    description: "Tailored solutions for your business"
                },
                {
                    imageSrc: SupportIconImage,
                    title: "24/7 Support",
                    description: "Always there when you need us"
                },
                {
                    imageSrc: ReliableIconImage,
                    title: "Reliable",
                    description: "Solutions you can trust"
                },
                {
                    imageSrc: SimpleIconImage,
                    title: "Easy",
                    description: "User-friendly experience"
                }
            ],
            ua: [
                {
                    imageSrc: ShieldIconImage,
                    title: "Unity",
                    description: "Високопродуктивна розробка ігор і додатків для максимізації результатів з просунутими інструментами Unity"
                },
                {
                    imageSrc: FastIconImage,
                    title: "Швидко",
                    description: "Ми оптимізуємо кожен етап розробки для створення високоякісних продуктів у найкоротший термін"
                },
                {
                    imageSrc: CustomizeIconImage,
                    title: "Налаштовуваний",
                    description: "Рішення на замовлення для вашого бізнесу"
                },
                {
                    imageSrc: SupportIconImage,
                    title: "Підтримка 24/7",
                    description: "Завжди на зв'язку"
                },
                {
                    imageSrc: ReliableIconImage,
                    title: "Надійний",
                    description: "Рішення, яким можна довіряти"
                },
                {
                    imageSrc: SimpleIconImage,
                    title: "Легкий",
                    description: "Зручний для користувача досвід"
                }
            ],
            ru: [
                {
                    imageSrc: ShieldIconImage,
                    title: "Unity",
                    description: "Высокопродуктивная разработка игр и приложений для максимизации результатов с передовыми инструментами Unity"
                },
                {
                    imageSrc: FastIconImage,
                    title: "Быстро",
                    description: "Мы оптимизируем каждый этап разработки для создания высококачественных продуктов в кратчайшие сроки"
                },
                {
                    imageSrc: CustomizeIconImage,
                    title: "Настраиваемый",
                    description: "Решения под ваш бизнес"
                },
                {
                    imageSrc: SupportIconImage,
                    title: "Поддержка 24/7",
                    description: "Всегда на связи"
                },
                {
                    imageSrc: ReliableIconImage,
                    title: "Надежный",
                    description: "Решения, которым можно доверять"
                },
                {
                    imageSrc: SimpleIconImage,
                    title: "Простой",
                    description: "Удобный для пользователя интерфейс"
                }
            ],
            ca: [
                {
                    imageSrc: ShieldIconImage,
                    title: "Unity",
                    description: "Desenvolupament d'aplicacions i jocs de rendiment alt per maximitzar els resultats amb les eines avançades de Unity"
                },
                {
                    imageSrc: FastIconImage,
                    title: "Ràpid",
                    description: "Optimitzem cada etapa del desenvolupament per crear productes de qualitat en el menor temps possible"
                },
                {
                    imageSrc: CustomizeIconImage,
                    title: "Personalitzable",
                    description: "Solucions personalitzades per al teu negoci"
                },
                {
                    imageSrc: SupportIconImage,
                    title: "Suport 24/7",
                    description: "Sempre allà quan ens necessites"
                },
                {
                    imageSrc: ReliableIconImage,
                    title: "Fiable",
                    description: "Solucions en les quals pots confiar"
                },
                {
                    imageSrc: SimpleIconImage,
                    title: "Fàcil",
                    description: "Experiència d'usuari senzilla"
                }
            ]
        },
    },
    FAQs: {
        title: {
            en: {
                heading: 'Questions',
                description: 'Here are some frequently asked questions about our hotels from our loving customers. Should you have any other questions, feel free to reach out via the contact form below.',
            },
            ua: {
                heading: 'Запитання',
                description: 'Ось кілька поширених запитань про наші готелі від наших люблячих клієнтів. Якщо у вас виникнуть інші запитання, будь ласка, зв\'яжіться з нами за допомогою контактної форми нижче.',
                imageSrc: 'https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80',
                imageContain: false,
                imageShadow: true,
            },
            ru: {
                heading: 'Вопросы',
                description: 'Здесь представлены некоторые часто задаваемые вопросы о наших отелях от наших любящих клиентов. Если у вас возникнут другие вопросы, не стесняйтесь обращаться к нам через контактную форму ниже.',
                imageSrc: 'https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80',
                imageContain: false,
                imageShadow: true,
            },
            ca: {
                heading: 'Preguntes',
                description: 'Aquí teniu algunes preguntes freqüents sobre els nostres hotels dels nostres estimats clients. Si teniu qualsevol altra pregunta, no dubteu a posar-vos en contacte amb el següent formulari de contacte.',
                imageSrc: 'https://images.unsplash.com/photo-1579427421635-a0015b804b2e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80',
                imageContain: false,
                imageShadow: true,
            },
        },
        questions: {
            en: [
                {
                    question: 'Is lunch provided free of cost ?',
                    answer: 'Yes, it is, if you have a membership with us. Otherwise it is charged as per the menu. Some limits do apply as to how much items can be included in your lunch. This limit is enough for any one person and merely exists to discourage abusal of the system.',
                },
                {
                    question: 'Do you have 2 Bedroom suites ?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                },
                {
                    question: 'Are Wi-Fi costs included in the price ?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                },
                {
                    question: 'Where can I reach you for support ?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                },
            ],
            ua: [
                {
                    question: 'Чи надається обід безкоштовно?',
                    answer: 'Так, це так, якщо у вас є членство в нашому клубі. В іншому випадку оплата стягується відповідно до меню. Існують певні обмеження щодо кількості страв, які можуть бути включені до вашого обіду. Цього ліміту достатньо для однієї людини, і він існує лише для того, щоб запобігти зловживанню системою.',
                },
                {
                    question: 'Чи є у вас люкси з 2 спальнями?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                },
                {
                    question: 'Чи включені витрати на Wi-Fi у вартість?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                },
                {
                    question: 'Де я можу звернутися до вас за підтримкою?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                },
            ],
            ru: [
                {
                    question: 'Предоставляется ли обед бесплатно?',
                    answer: 'Да, это так, если у вас есть членство в нашем клубе. В противном случае оплата взимается в соответствии с меню. Существуют определенные ограничения по количеству блюд, которые могут быть включены в ваш обед. Этого лимита достаточно для одного человека, и он существует только для того, чтобы предотвратить злоупотребление системой.',
                },
                {
                    question: 'Есть ли у вас люксы с 2 спальнями?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                },
                {
                    question: 'Включены ли расходы на Wi-Fi в стоимость?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                },
                {
                    question: 'Где я могу обратиться к вам за поддержкой?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                },
            ],
            ca: [
                {
                    question: 'El dinar és gratuït?',
                    answer: 'Sí, això és cert si tens una subscripció al nostre club. En cas contrari, es cobrarà el pagament segons el menú. Hi ha certes restriccions sobre el nombre de plats que es poden incloure al vostre àpat. Aquest límit és suficient per a una persona i només està allà per evitar l\'abús del sistema.',
                },
                {
                    question: 'Tens suites de 2 habitacions?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                },
                {
                    question: 'Les despeses de Wi-Fi estan incloses en el preu?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                },
                {
                    question: 'On puc contactar amb tu per obtenir assistència?',
                    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                },
            ],
        }
    },
    footer: {
        en: {
            contact: 'Contact Us',
            links: 'Quick Links',
            about: 'About Us',
            FAQs: 'FAQs',
            product: 'Product',
            projects: 'Projects',
            legal: 'Legal',
            privacy: 'Privacy Policy',
            terms: 'Terms of Service',
        },
        ua: {
            contact: 'Зв\'яжіться з нами',
            links: 'Швидкі посилання',
            about: 'Про нас',
            FAQs: 'Часті питання',
            product: 'Продукт',
            projects: 'Проекти',
            legal: 'Права',
            privacy: 'Політика конфіденційності',
            terms: 'Умови надання послуг',
        },
        ru: {
            contact: 'Свяжитесь с нами',
            links: 'Быстрые ссылки',
            about: 'О нас',
            FAQs: 'Частые вопросы',
            product: 'Продукт',
            projects: 'Проекты',
            legal: 'Права',
            privacy: 'Политика конфиденциальности',
            terms: 'Условия предоставления услуг',
        },
        ca: {
            contact: 'Contacta amb nosaltres',
            links: 'Enllaços ràpids',
            about: 'Sobre Nosaltres',
            FAQs: 'Preguntes freqüents',
            product: 'Producte',
            projects: 'Projectes',
            legal: 'Legal',
            privacy: 'Privadesa',
            terms: 'Condicions del servei',
        },
    },
    contactUs: {
        en: {
            contact: 'Contact Us',
            title: 'Feel free to get in touch with us.',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            email: 'Your Email Address',
            name: 'Full Name',
            subject: 'Subject',
            message: 'Your Message Here',
            button: 'Send'
        },
        ua: {
            contact: 'Зв\'язатися з нами',
            title: 'Не соромтеся звертатися до нас.',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            email: 'Ваша електронна адреса',
            name: 'Повне ім\'я та прізвище',
            subject: 'Тема',
            message: 'Ваше повідомлення тут',
            button: 'Надіслати'
        },
        ru: {
            contact: 'Связаться с нами',
            title: 'Не стесняйтесь обращаться к нам.',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            email: 'Ваш электронный адрес',
            name: 'Полное имя и фамилия',
            subject: 'Тема',
            message: 'Ваше сообщение здесь',
            button: 'Отправить'
        },
        ca: {
            contact: 'Contacta amb nosaltres',
            title: 'No dubteu a contactar amb nosaltres.',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            email: 'La teva adreça de correu electrònic',
            name: 'Nom i cognoms complets',
            subject: 'Tema',
            message: 'El teu missatge és aquí',
            button: 'Enviar'
        },

    },
    projectDetails: {
        en: {
            technology: 'Technology:',
            socialMedia: 'Social media:',
            tags: 'Tags:',
            about: 'About this game.',
        },
        ua: {
            technology: 'Технології:',
            socialMedia: 'Соціальні мережі:',
            tags: 'Теги:',
            about: 'Про цю гру.',
        },
        ru: {
            technology: 'Технологии:',
            socialMedia: 'Социальные сети:',
            tags: 'Теги:',
            about: 'Об этой игре.',
        },
        ca: {
            technology: 'Tecnologia:',
            socialMedia: 'Social media:',
            tags: 'Etiquetes:',
            about: 'Sobre aquest joc.',
        },
    }
};


