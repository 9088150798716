import React, { useContext } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { translations } from 'Projects';
import { LanguageContext } from "../../LanguageContext";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
    ${ tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24` }
`;
const Subheading = tw( SubheadingBase )`mb-4`;
const Heading = tw( SectionHeading )`w-full`;
const Description = tw( SectionDescription )`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
    ${ tw`md:w-1/2 lg:w-1/3 max-w-sm` }
`;

const Card = styled.div`
    ${ tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8` }
    .imageContainer {
        ${ tw`border text-center rounded-full p-5 flex-shrink-0` }
        img {
            ${ tw`w-6 h-6` }
        }
    }

    .textContainer {
        ${ tw`sm:ml-4 mt-4 sm:mt-2` }
    }

    .title {
        ${ tw`mt-4 tracking-wide font-bold text-2xl leading-none` }
    }

    .description {
        ${ tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose` }
    }
`;

export default () => {
    const { selectedLanguage } = useContext(LanguageContext);

    const cardText = translations.features.card[ selectedLanguage ] || {};
    const expertiseText = translations.features.expertise[ selectedLanguage ] || {};

    return (
        <Container id={ 'expertise' }>
            <ThreeColumnContainer>
                {/*<Subheading>{ cardText.subheading }</Subheading>*/}
                <Heading>{ cardText.heading }</Heading>
                <Description>{ cardText.description }</Description>
                <VerticalSpacer/>
                { expertiseText.map( ( card, i ) => (
                    <Column key={ i }>
                        <Card>
                            <span className="imageContainer">
                                <img src={ card.imageSrc } alt=""/>
                            </span>
                            <span className="textContainer">
                                <span className="title">{ card.title }</span>
                                <p className="description">{ card.description }</p>
                            </span>
                        </Card>
                    </Column>
                ) ) }
            </ThreeColumnContainer>
        </Container>
    );
};
