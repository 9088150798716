import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";
import styled, { keyframes } from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import { ReactComponent as CheckIcon } from "feather-icons/dist/icons/check.svg";
import { CatHead } from "../3d/CatHead/CatHead";
import { translations } from "../../Projects";
import { LanguageContext } from "../../LanguageContext";
import axios from "axios";

const fadeInBackground = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const fadeInModal = keyframes`
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;

const fadeOutBackground = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`;

const fadeOutModal = keyframes`
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.95);
    }
`;

const ModalOverlay = styled.div`
  ${tw`fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50`}
  animation: ${props => (props.closing ? fadeOutBackground : fadeInBackground)} 0.3s ease-out;
`;

const ModalContainer = styled.div`
  ${tw`bg-white rounded-lg shadow-lg p-6`}
  width: 100%;
  max-width: 1280px;
  height: auto;
  animation: ${props => (props.closing ? fadeOutModal : fadeInModal)} 0.3s ease-out;
`;


const TwoColumn = tw.div`flex flex-col md:flex-row justify-between`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`hidden md:block md:w-5/12 flex-shrink-0 h-80 md:h-auto`;

const TextColumn = styled(Column)((props) => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Container = styled.div`
  ${tw`relative w-full h-full mx-auto p-0`}
`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;
const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = styled.input`
    ${tw`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300`}

    &:hover {
        border-image-source: linear-gradient(90deg, rgba(0, 94, 255, 1) 0%, rgb(0, 170, 213) 100%);
        border-image-slice: 1;
        border-width: 0 0 2px 0;
        border-style: solid;
    }
`;

const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
  resize: none
`;

const SubmitButton = styled(PrimaryButtonBase)`
  ${tw`inline-block mt-8 flex items-center justify-center`}
  transition: all 0.3s ease-in-out;
  position: relative;

  svg {
    position: absolute;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  &.success svg {
    opacity: 1;
    transform: scale(1);
  }

  span {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  &.success span {
    opacity: 0;
  }
`;

const CloseButton = styled.button`
  ${tw`bg-red-500 text-white rounded p-2 absolute top-0 right-0`}
  &:hover {
    ${tw`bg-red-700`}
  }
`;

const ContactUsModal = ({ isOpen, onClose }) => {
    const { selectedLanguage } = useContext(LanguageContext);
    const translation = translations.contactUs[selectedLanguage] || {};

    const [formData, setFormData] = useState({
        message: ""
    });

    const [closing, setClosing] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const sendEmail = (e) => {
        e.preventDefault();

        const formPayload = new FormData();
        Object.keys(formData).forEach((key) => formPayload.append(key, formData[key]));

        axios
        .post("https://nasty.cat/api/index.php?action=contact", formPayload)
        .then((response) => {
            if (response.data.success) {
                console.log(response.data.message);
                setSubmitted(true);
                setFormData({ ...formData, message: "" }); // Очистка полей "Сообщение"
                setTimeout(() => {
                    closeWithAnimation();
                }, 600);
            } else {
                console.log(response.data.error || "Ошибка при отправке сообщения.");
            }
        })
        .catch((error) => {
            console.error("Ошибка при отправке сообщения:", error);
            alert("Произошла ошибка при отправке сообщения.");
        });
    };

    const closeWithAnimation = () => {
        setClosing(true);
        setTimeout(() => {
            setClosing(false);
            onClose();
        }, 300);
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
            setSubmitted(false);
        } else {
            document.body.style.overflow = "unset";
        }
    }, [isOpen]);

    if (!isOpen && !closing) return null;

    return (
        <ModalOverlay closing={closing}>
            <ModalContainer closing={closing}>
                <Container>
                    <CloseButton onClick={closeWithAnimation}>
                        <CloseIcon tw="w-6 h-6" />
                    </CloseButton>
                    <TwoColumn>
                        <ImageColumn>
                            <CatHead trackOnWindow />
                        </ImageColumn>
                        <TextColumn textOnLeft>
                            <TextContent>
                                {/*<Subheading>{translation.contact}</Subheading>*/}
                                <Heading>{translation.title}</Heading>
                                <Description>{translation.description}</Description>
                                <Form method="POST" action="" onSubmit={sendEmail}>
                                    <Input
                                        type="email"
                                        name="from"
                                        value={formData.from}
                                        onChange={handleInputChange}
                                        placeholder={translation.email}
                                    />
                                    <Input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        placeholder={translation.name}
                                    />
                                    <Input
                                        type="text"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleInputChange}
                                        placeholder={translation.subject}
                                    />
                                    <Textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        placeholder={translation.message}
                                    />
                                    <SubmitButton type="submit" className={submitted ? "success" : ""}>
                                        <CheckIcon tw="w-6 h-6 absolute" />
                                        <span>{translation.button}</span>
                                    </SubmitButton>
                                </Form>
                            </TextContent>
                        </TextColumn>
                    </TwoColumn>
                </Container>
            </ModalContainer>
        </ModalOverlay>
    );
};

export default ContactUsModal;
