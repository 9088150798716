import React, { useEffect, useRef } from "react";
import * as BABYLON from "babylonjs";
import "babylonjs-loaders"; // Импортируем поддержку загрузки моделей, таких как glTF

const NastyCat = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const engine = new BABYLON.Engine(canvas, true, {
      preserveDrawingBuffer: true,
      stencil: true,
      alpha: true, // Добавляем параметр для прозрачности
    });

    // Отключаем стандартный экран загрузки
    engine.loadingScreen = null;
    engine.displayLoadingUI = function() {};
    engine.hideLoadingUI = function() {};

    // Отключаем экран загрузки SceneLoader
    BABYLON.SceneLoader.ShowLoadingScreen = false;

    const createScene = () => {
      const scene = new BABYLON.Scene(engine);

      // Создаем камеру
      const camera = new BABYLON.ArcRotateCamera(
          "Camera",
          (220 * Math.PI) / 180,
          (100 * Math.PI) / 180,
          35,
          new BABYLON.Vector3(0, 0, 0),
          scene
      );
      camera.detachControl();
      scene.clearColor = new BABYLON.Color4(1, 1, 1, 0); // Прозрачный фон (RGBA)

      // Создаем свет
      const light = new BABYLON.HemisphericLight(
          "light",
          new BABYLON.Vector3(1, 1, 0),
          scene
      );

      // Загружаем модель .gltf и позиционируем её
      BABYLON.SceneLoader.Append("", "scene.gltf", scene, () => {
        const model = scene.meshes[scene.meshes.length - 1];

        // Устанавливаем начальную прозрачность
        scene.meshes.forEach((mesh) => {
          mesh.position = new BABYLON.Vector3(0, -12, 0);
          mesh.visibility = 0; // Начальная прозрачность
        });

        model.scaling = new BABYLON.Vector3(1, 1, 1);

        // Запуск анимаций
        scene.animationGroups.forEach((group) => {
          group.start(true);
        });

        // Камера направлена на модель
        camera.setTarget(model.position);

        // Создаем анимацию плавного появления
        const fadeInAnimation = new BABYLON.Animation(
            "fadeInAnimation",
            "visibility",
            60,
            BABYLON.Animation.ANIMATIONTYPE_FLOAT,
            BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
        );

        // Ключевые кадры для анимации (появление от 0 до 1)
        const keys = [
          { frame: 50, value: 0 },
          { frame: 60, value: 1 },
        ];
        fadeInAnimation.setKeys(keys);

        // Применяем анимацию ко всем частям модели
        scene.meshes.forEach((mesh) => {
          mesh.animations = [fadeInAnimation];
          scene.beginAnimation(mesh, 0, 60, false);
        });
      });

      return scene;
    };

    const scene = createScene();

    // Запуск рендеринга сцены
    engine.runRenderLoop(() => {
      scene.render();
    });

    // Обработчик изменения размера окна
    const handleResize = () => {
      engine.resize();
    };

    window.addEventListener("resize", handleResize);

    // Очистка ресурсов при размонтировании компонента
    return () => {
      engine.dispose();
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
      <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            outline: "none",
          }}
      />
  );
};

export default NastyCat;
