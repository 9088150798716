import React, { useContext, useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";
import { getPlatformIcon, getToolIcon } from 'icons.js'
import { getProjects } from "../../Projects";
import { LanguageContext } from "../../LanguageContext";

const ProjectData = await getProjects();

const NavLink = tw.a`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-0 sm:py-0`;
const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-4 lg:py-4`;
const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw( SectionHeading )``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled( PrimaryButtonBase )`
    ${ tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2` }
    svg {
        ${ tw`w-6 h-6` }
    }
`;
const PrevButton = tw( ControlButton )``;
const NextButton = tw( ControlButton )``;

const CardSlider = styled(Slider)`
    ${tw`mt-16 overflow-hidden`}
    .slick-track {
        ${tw`flex`}
    }

    .slick-slide {
        ${tw`h-auto flex justify-center mb-1`}
    }
`;

const Card = tw.div`h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div`
    ${ ( { imageSrc } ) => `
    background-image: url("${ imageSrc }");
  ` }
    ${ tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl` }
`;

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;
const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;
const SecondaryInfoContainer = tw.div`flex flex-row sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-start mr-2 my-2 sm:my-0`;
const IconContainer = styled.div`
    ${ tw`inline-block rounded-full p-0 text-black cursor-pointer transition-transform transform` }
    &:hover {
        ${ tw`scale-125` }
    }

    a {
        display: flex;
    }

    svg {
        ${ tw`w-6 h-6` }
    }
`;

const PrimaryButton = tw( PrimaryButtonBase )`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;

export default ( { ignoreProjectId = '', } ) => {
    const { selectedLanguage } = useContext( LanguageContext );

    const [ sliderRef, setSliderRef ] = useState( null );
    const sliderSettings = {
        arrows: false, slidesToShow: 3, responsive: [ {
            breakpoint: 1280, settings: {
                slidesToShow: 2,
            }
        }, {
            breakpoint: 900, settings: {
                slidesToShow: 1,
            }
        }, ]
    };

    const ToolContainer = tw.div`flex items-center`;
    const PlatformsContainer = tw.div`flex items-center ml-auto`;

    return ( <Container id={ 'projects' }>
        <Content>
            <HeadingWithControl>
                <Heading>Projects</Heading>
                <Controls>
                    <PrevButton onClick={ sliderRef?.slickPrev }><ChevronLeftIcon/></PrevButton>
                    <NextButton onClick={ sliderRef?.slickNext }><ChevronRightIcon/></NextButton>
                </Controls>
            </HeadingWithControl>
            <CardSlider ref={ setSliderRef } { ...sliderSettings }>
                { ProjectData.filter( p => p.id !== ignoreProjectId ).map( ( project, index ) => {
                    const shortDescription = project.shortDescription[ selectedLanguage ] || project.shortDescription[ 'en' ];

                    return (
                        <Card key={ project.id }>
                            <CardImage imageSrc={ project.icon }/>
                            <TextInfo>
                                <TitleReviewContainer>
                                    <Title>{ project.title }</Title>
                                </TitleReviewContainer>
                                <SecondaryInfoContainer>
                                    <ToolContainer>
                                        <IconWithText>
                                            { project.technology.map( technology => (
                                                <IconContainer style={ { marginRight: '0.5rem' } }>
                                                    { getToolIcon( technology.toLowerCase() ) }
                                                </IconContainer>
                                            ) ) }
                                        </IconWithText>
                                    </ToolContainer>
                                    <PlatformsContainer>
                                        { project.platforms && Object.entries( project.platforms ).map( ( [ platform, link ], idx ) => {

                                            return (
                                                <IconWithText key={ idx }>
                                                    <a
                                                        style={ { display: 'flex' } }
                                                        href={ link }
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        <IconContainer>
                                                            { getPlatformIcon( platform.toLowerCase() ) }
                                                        </IconContainer>
                                                    </a>
                                                </IconWithText> );
                                        } ) }
                                        { project.socialMedia && Object.entries( project.socialMedia ).map( ( [ socialMedia, link ], idx ) => {

                                            return (
                                                <IconWithText key={ idx }>
                                                    <a
                                                        style={ { display: 'flex' } }
                                                        href={ link }
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        <IconContainer>
                                                            { getPlatformIcon( socialMedia.toLowerCase() ) }
                                                        </IconContainer>
                                                    </a>
                                                </IconWithText> );
                                        } ) }
                                    </PlatformsContainer>
                                </SecondaryInfoContainer>
                                <Description>{ shortDescription }</Description>
                            </TextInfo>
                            <NavLink href={ `/Project/${ project.slug }` }>
                                <PrimaryButton>Details</PrimaryButton>
                            </NavLink>
                        </Card> );
                } ) }
            </CardSlider>
        </Content>
    </Container> );
};
